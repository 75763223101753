// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

$gray-dark: #004b91 !default;

$enable-flex: true;

$navbar-light-hover-color: #004b91;

$brand-primary: #004b91 !default;

$card-cap-bg: #004b91 !default;

$card-cap-color: #ffffff;

$close-color: #ffffff;

$navbar-top-background: #004b91 !default;
$navbar-bottom-background: #004b91 !default;

$navbar-top-color: #fff;
$navbar-top-active-color: #e5e500;
$navbar-top-hover-color: #e2e2e2;

$navbar-bottom-color: #fff;
$navbar-bottom-active-color: #e5e500;
$navbar-bottom-hover-color: #e2e2e2;

$nav-tabs-link-active-color: #fff;
$nav-tabs-link-active-bg: #004b91;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$red: #e8334b !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$cyan: #17a2b8 !default;

$theme-colors: (
	primary: #004b91,
	secondary: #ff7300,
	// $gray-600,
		success: $green,
	info: $cyan,
	warning: $yellow,
	danger: $red,
	light: $gray-100,
	dark: $gray-800,
) !default;

@import '~bootstrap/scss/bootstrap.scss';

@import '../../../../vendor/z5internet/Demora/src/resources/assets/scss/scss.scss';

.btn-group-xss > .btn,
.btn-xss {
	padding: 3px 5px;
	font-size: 9px;
	line-height: 1.2;
	border-radius: 2px;
}
