.authorisedUser .navbarTop .navbar-brand {
	margin-left: 40px;
}

body {
	padding-top: 77px !important;
	padding-bottom: 94px !important;
}

body .navbarTop {
	height: 72px;
}

.modal-header {
	background-color: #004b91;
	color: #ffffff;
}
